// include any client-side code here

// import styles
// NOTE: importing here means that it gets inserted into the document head after the page load.
// Consider an alternative - inline all styles, or create a separate entry point just for global styles.
import style from "Assets/style/main.scss";

const getAll = query => {
  // given a css selector query, return an array of elements
  return Array.prototype.slice.call(document.querySelectorAll(query), 0);
};

const extLink = link =>
  // given a dom element, add target="_blank" if href is to an external domain
  link.getAttribute("href").indexOf("/") !== 0
    ? link.setAttribute("target", "_blank")
    : link;

// on content loaded:
document.addEventListener("DOMContentLoaded", () => {
  // SuperMenu controls
  const toggleSuperMenu = getAll(".eSuperMenu");
  const superMenu = document.getElementById("SuperMenu");

  toggleSuperMenu.forEach(el => {
    el.addEventListener("click", e => {
      // find supermenu, toggle visible
      el.classList.toggle("is-active");
      superMenu.classList.toggle("is-active");
    });
  });

  // open all external links in new tab
  const links = getAll(".content a");
  links.forEach(link => extLink(link));

  // open/close event content
  const events = getAll(".events .block");
  const eventClose = getAll(".event__close");

  const closeEvents = events => {
    // given an array of events, close them all
    events.forEach(el => el.classList.remove("is-active"));
  };

  events.forEach(el => {
    el.addEventListener("click", e => {
      e.preventDefault();
      // close all others
      closeEvents(events);
      // add .is-active to this content
      el.classList.add("is-active");
    });
  });

  eventClose.forEach(el => {
    el.addEventListener("click", e => {
      e.preventDefault();
      e.stopPropagation();
      // close the parent block of the clicked close button
      e.target.closest(".events .block").classList.remove("is-active");
    });
  });
});
